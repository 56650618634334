const getHost = () => {
    return process.env.NODE_ENV === 'production' ? '/api/' : 'http://localhost:5020/';
}

export const getStores = () =>
    fetch(getHost() + 'stores/')
        .then(r => r.json());

export const getStoreDetails = code =>
    fetch(getHost() + `stores/${code}/`)
        .then(r => r.json());