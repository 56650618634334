import React from "react";

export default props => {
    return (!props.details ? <div/> : 
    <div className="store-details">
    <table>
    <tr><th>Name</th><td>{props.details.name} ({props.details.code})</td></tr>
        {props.details.localWeather &&<tr><th>Temperature</th><td>{props.details.localWeather.tempC} deg C</td></tr>}
        {props.details.localWeather && <tr><th>Weather</th><td>{props.details.localWeather.description}</td></tr>}
        {props.details.localWeather &&<tr><td colspan="2"><img src={props.details.localWeather.icon} alt="weather" /></td></tr>}
    </table>
    </div>)
}