import React from 'react';
import './App.css';
import Stores from './components/stores/';

function App() {
  return (
    <div className="App">
        <Stores />
    </div>
  );
}

export default App;
