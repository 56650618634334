import React from "react";
import {getStores, getStoreDetails} from '../../utils/api.js';
import StoreDetails from '../storeDetails/';
import StoreList from '../storeList/';

class Stores extends React.Component {
    constructor(){
        super();
        this.state = {stores:[]};
        this.populateStores();
        this.viewStore = this.viewStore.bind(this);
    }
    populateStores() {
        getStores().then(stores => {
            this.setState({stores});
        });
    }
    viewStore(code){
        getStoreDetails(code).then(storeDetails => {
            this.setState({...this.state, storeDetails});
        });
    }
    render() {
        return (
            <div class="stores">
                <StoreList stores={this.state.stores} storeSelected={this.viewStore} />
                <StoreDetails details={this.state.storeDetails} />
            </div>
        );
    }
}

export default Stores;